.async-select-paginate {
    &.form-select {
        border: 0;
        padding-top: 1px;
        padding-right: 0;
        padding-bottom: 1px;
        padding-left: 3px;
        .css-1an5g1v-control {
            background-color: transparent;
            border: 0;
            .css-1xc3v61-indicatorContainer {
                opacity: 0;
            }
        }
        .css-sgap5j-control {
            background-color: transparent;
            border: 0;
            box-shadow: none;
            .css-15lsz6c-indicatorContainer {
                opacity: 0;
            }
        }
        .css-1fx0oa4-menu {
            border: 2px solid rgba(0, 0, 0, 0.1);
            border-top-width: 0;
            box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.1);
            border-radius: 0 0 0.475rem 0.475rem;
            background-color: #ffffff;
            width: 97%;
            font-weight: 400;
            font-size: 13px;
            margin: 0;
            top:98%;
            .css-4ljt47-MenuList{
                .css-mu3o70-option{
                    background-color: #f7f8fa;
                }
            }
        }
    }
}
