$pink : #FF2D9A;
$pink-hover : #ff0687;

$purple : #622CCC;

$orange : #FE7411;

$yellow : #FFC300;

$green : #0bd377;
$green-hover : #0ce682;

