@keyframes jumpUp {
  0% {
    transform: translate3d(0, 0, 0);
  }

  40% {
    transform: translate3d(0, 20px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes jumpDown {
  0% {
    transform: translate3d(0, 0, 0);
  }

  40% {
    transform: translate3d(0, -20px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes jumpright {
  0% {
    transform: translate3d(0, 0, 0);
  }

  40% {
    transform: translate3d(-20px, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}