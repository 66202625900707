.aside-logo{}
.aside-logo span{
    position: relative;
    z-index: 0;
    top: 4px;
}

.user-name span{
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: #3f4254;
    background-color: #f5f8fa;
    border-radius: 6px;
    font-size: 18px;
}

.user-name{
    display: flex;
    align-items: center;
}
.user-name span{
    width: 36px;
    height: 36px;
    font-size: 15px;
    font-weight: 600;
}

hr.dashed {
    border-top: 2px dashed #8c8b8b;
    background-color: transparent;
}

.custom-btn-font-size {
    font-size: 1rem;
}

.linkies{}
.linkie{
    background-color: #fff !important;
    border-radius: 6px;
    margin-bottom: 22px;
    padding: 16px 6px !important;
    display: flex;
    align-items: center;
    box-shadow:  0 0 10px 0 rgb(76 87 125 / 3%);
}
.linkie:hover{
    box-shadow:  0 0 10px 0 rgb(76 87 125 / 12%);
}
.linkie .symbol span,
.user-name span{
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: #3f4254;
    background-color: #f5f8fa;
    border-radius: 6px;
    font-size: 18px;
}
.linkie .symbol span img{
    height: 25px;
}
.linkie:not(:hover) .buttons{
    opacity: 0;
}

.user-name{
    display: flex;
    align-items: center;
}
.user-name span{
    width: 36px;
    height: 36px;
    font-size: 15px;
    font-weight: 600;
}

.modal-50 {
    width: 50% !important;
    max-width: 50% !important;
}

.modal-70 {
    width: 70% !important;
    max-width: 70% !important;
}

.modal.add-new-linkie{}
.modal.add-new-linkie .lists label{
    margin-bottom: 12px;
    width: 100%;
    text-align: left;
}
.modal.add-new-linkie .lists .btn-check:checked + .btn.btn-primary,
.modal.add-new-linkie .lists .btn-check:checked + .btn.btn-light-primary {
  padding-right: calc(1.5em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px 16px;
}
.modal.add-new-linkie .lists .btn-light-primary{
  border:2px solid !important;
}
.modal.add-new-linkie .lists .btn-check + .btn.btn-light-primary,
.modal.add-new-linkie .lists .btn-check:checked + .btn.btn-light-primary{
  border-color: #333333 !important;
}

hr.dashed {
	border-top: 2px dashed #8c8b8b;
    background-color: transparent;
}

/* starts, tabs css */
.c-tabs .nav-pills{
    display: inline-flex;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 60px !important;
    margin-bottom: 26px;
    padding: 6px;
  }
  .c-tabs .nav-link{
    padding: 6.5px;
    font-weight: 600;
    border-radius: 48px !important;
    min-width: 178px;
  }
  .c-tabs .nav-pills .nav-link.active{
    color: #fff;
  }
  @media (min-width: 1025px) {
    .c-tabs .nav-pills{
      position: fixed;
      background-color: #EEF0F8;
      top: 10.5px;
      z-index: 100;
      left: calc(50% - 149px);
    }
    .c-tabs .nav-link{
      padding: 6.5px 30px;
    }
  }
  /* end, tabs css */

#notification-container {
    position: fixed;
    top: 200px;
    left: 20%;
    right: 20%;
    z-index: 9991;
    display: none;
    pointer-events: none;
}

#notification-container #notification-content {
    position: relative;
    margin: 0 auto !important;
    width: 400px !important;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    color: white;
    font-size: 28px;
    line-height: 52px;
}

#notification-container #notification-content.error {
    background: rgba(255, 0, 0, 1) !important;
}

.custom-btn-font-size {
    font-size: 1rem;
}

/* Start TABLE Styles */
.Table__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Table__itemCount {
    /* margin-top: 10px; */
    font-size: 14px;
}

.Table__pagination {
    display: flex;
    justify-content: flex-start;
    padding: 20px 10px;
}

.Table__page {
    display: flex;
    justify-content: flex-end;
    padding: 25px 15px;
}

.Table__visiblePagesWrapper {
    margin-left: 10px;
    margin-right: 10px;
}

.Table__pageButton {
    font-size: 15px;
    outline: none;
    border: none;
    margin-left: 2px;
    margin-right: 2px;
    height: 29px;
    width: 29px;
    border-radius: 5px;
    background-color: transparent;
    color: #f1faff;
    cursor: pointer;
}

.Table__pageButton--active,
.Table__pageButton:hover {
    color: #fff;
    font-weight: bold;
    -webkit-transition: background-color 3.3s ease;
    background-color: #003734;
}

.switchPageButton {
    font-size: 15px;
    outline: none;
    border: none;
    margin-left: 2px;
    margin-right: 2px;
    height: 29px;
    width: 29px;
    border-radius: 5px;
    background-color: #f1faff;
    color: #003734;
    cursor: pointer;
}

.switchPageButton:disabled {
    cursor: not-allowed;
    color: #e0e5f5;
    background-color: #fbfcff;
}

.switchPageButton:disabled:hover {
    cursor: not-allowed;
    color: #f1f4fc;
    font-weight: normal;
    background-color: #fbfcff;
}

.switchPageButton:active,
.switchPageButton:hover {
    color: #fff;
    background-color: #003734;
}

.select-page-text {
    color: #003734;
    font-size: 13px;
    margin-left: 15px;
}

.select-page {
    border-width: 0;
    color: #5e6278;
    font-size: 13px;
    font-weight: 600;
    height: 29px;
    padding-left: 10px;
    width: 60px;
    border-radius: 5px;
    background: url(../../assets/media/svg/chevron-down.svg) no-repeat right #f5f8fa;
    -webkit-appearance: none;
}

.select-page option {
    font-size: 13px;
    font-weight: bold;
}

.select-page:hover {
    border-width: 0;
    color: #fff;
    background-color: #003734;
}

.select-page:active {
    border-width: 0;
    color: #fff;
    background-color: #003734;
    -webkit-appearance: none;
}

.Table__pagination .switchPageButton:hover,
.Table__pageButton:hover,
.Table__pageButton--active {
    background: #003734;
}

.rt-td {
    color: #6c7293;
    padding: 0;
}

.rt-tr-group:hover {
    background-color: #fafbfc;
}

.-sort-desc,
.-sort-asc {
    box-shadow: none !important;
}

.rt-thead .rt-resizable-header-content {
    color: #B5B5C3 !important;
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.1rem;
}

.rt-thead .-sort-asc .rt-resizable-header-content,
.rt-thead .-sort-desc .rt-resizable-header-content {
    color: #003734 !important;
}

.rt-thead .rt-th {
    padding: 0.75rem 1rem !important;
}

.-sort-asc .rt-resizer::before {
    content: "\2191";
    color: #003734;
    vertical-align: middle;
    font-weight: 600;
}

.-sort-desc .rt-resizer::after {
    content:"\2193";
    color: #003734;
    vertical-align: middle;
    font-weight: 600;
}

.ReactTable .rt-resizer {
    top: 5px;
}

.ReactTable .rt-td {
    align-self: center;
}

.Table__page .select-page:hover {
    background-color: #003734;
}

.rt-resizable-header-content {
    color: #595d6e !important;
    font-weight: 500;
}

.ReactTable .rt-expander:after {
    border-top: 7px solid #003734 !important;
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
    border: 0 !important;
}

.ReactTable .rt-thead.-header {
    padding-bottom: 0 !important;
}

.ReactTable .rt-tr{
    padding-top: unset !important;
    padding-bottom: unset !important;
    overflow-x: scroll;
}

.ReactTable .rt-tbody .rt-td {
    border-right: unset !important;
}

.ReactTable .switchPageButton,
.ReactTable .Table__pageButton {
    border-radius: 50% !important;
    font-size: unset !important;
}
/* End TABLE Styles */

/* Start React DateRange Picker*/
.react-daterange-picker__wrapper {
    border: unset !important;
}
/* End React DateRange Picker*/

.MuiTooltip-tooltip {
    font-size: 13px !important;
}

.custom-btn-font-size {
    font-size: 1rem;
}

.btn-outline-primary {
    border: 2px solid #333333 !important;
}

/* starts : custom react-switch css */
.c-switch {
    top: 2.5px;
}
/* end : custom react-switch css */
