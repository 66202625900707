.pages {

  // login
  &.login {

    // rectangles
    .rectangle {
      span {
        width: 20vw;
        height: 20vw;
        border-radius: 13%;

        @include media-breakpoint-md {
          width: 14vw;
          height: 14vw;
        }

        @include media-breakpoint-xl {
          width: 240px;
          height: 240px;
          border-radius: 32px;
        }

        display: flex;
        transform: rotate(17deg);
      }

      position: absolute;
      top:26%;
      right: -12%;
      animation: jumpright 6s infinite linear;

      &.two {
        top: auto;
        bottom: -12%;
        right: 10%;
        animation-name: jumpDown;

        span {
          transform: rotate(9deg);
        }
      }

      &.three {
        top: auto;
        bottom: -14%;
        right: auto;
        left: -6%;
        animation-name: jumpUp;

        span {
          transform: rotate(-22deg);
        }
      }
    }

    .icon-linkie {
      //transform: rotate(-24deg);
    }

  }

}