.bg-purple{
  background-color: $purple;
}
.bg-pink{
  background-color: $pink;
}
.bg-orange{
  background-color: $orange;
}
.bg-yellow{
  background-color: $yellow;
}