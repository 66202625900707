.one-word-per-line { // Separate sentence to one word per line
  word-spacing: 9999rem;
}

.fw-7{
  font-weight: 700;
}

.fw-8{
  font-weight: 800;
}

.fw-9{
  font-weight: 900;
}