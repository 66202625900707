//
// react-table
//

// mixins
@mixin first-last-childs {
    &:first-child {
        padding-left: 0 !important;
    }

    &:last-child {
        padding-right: 0 !important;
    }
}

@mixin rt-th {
    padding: 13px 9.75px !important;
}


.ReactTable {
    border: unset !important;
    line-height: 1.5;

    .rt-table {
        --bs-table-bg: transparent;
        --bs-table-accent-bg: transparent;
        --bs-table-striped-color: #181C32;
        --bs-table-striped-bg: rgba(245, 248, 250, 0.75);
        --bs-table-active-color: #181C32;
        --bs-table-active-bg: #F5F8FA;
        --bs-table-hover-color: #181C32;
        --bs-table-hover-bg: #F5F8FA;
        width: 100%;
        margin-bottom: 1rem;
        color: #181C32;
        vertical-align: middle;
        border-color: #EFF2F5;
    }

    .rt-thead {

        // background-color: rgb(245, 248, 250);
        // border-radius: 6px;
        vertical-align: bottom;

        &.-header {
            padding-top: 0 !important;
            box-shadow: none !important;

            .rt-tr {
                border-bottom-color: #E4E6EF;
                border-bottom-width: 1px;
                border-bottom-style: dashed;

                .rt-th {
                    font-weight: 600 !important;
                    @include rt-th;
                    line-height: inherit;

                    .rt-resizable-header-content {
                        color: #A1A5B7 !important;
                    }

                    @include first-last-childs;
                }
            }
        }

        .rt-th {
            padding: 13px 13px 7px 13px !important;
        }

        .rt-resizable-header-content {
            letter-spacing: normal;
            text-transform: capitalize;
            font-size: 13px;
        }

        .rt-resizer {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            right: 0;

            &::after {
                margin-top: 3px;
            }
        }
    }

    .rt-tbody {
        .rt-tr-group {
            font-size: 14px;
            border-bottom: 1px dashed #E4E6EF !important;

            &:last-child {
                border-bottom: 0 !important;
            }

            .rt-tr {
                .rt-td {
                    font-weight: 600 !important;
                    @include rt-th;
                    line-height: inherit;
                    color: #181C32 !important;

                    //min-height: 62px;
                    @include first-last-childs;
                }
            }
        }
    }

}

.pagination{
    .from-start{
        i{
            &.previous{
                &.first{
                    padding-left: 5px;
                    position: relative;
                    z-index: 0;
                    left: -2.5px;
                }
            }
        }
    }
    .to-end{
        i{
            &.next{
                &.first{
                    padding-left: 5px;
                    position: relative;
                    z-index: 0;
                    left: -2px;
                }
            }
        }
    }
}