.btn {

  // Btn Pink
  &.btn-pink {
    background-color: $pink;
    border-color: $pink;
    color: #fff;
    &:hover,
    &:active,
    &:focus {
      &:not(.btn-active) {
        background-color: $pink-hover;
      }
    }
  }

  // Btn success 
  &.btn-success {
    background-color: $green;
    border-color: $green;
    &:hover,
    &:active,
    &:focus {
      &:not(.btn-active) {
        background-color: $green-hover !important;
      }
    }
  }

}

// Button Group
.buttons-grid {
  display: inline-grid !important;
  grid-gap: 13px;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  // Grid Gaps
  &.gg-0 {
    grid-gap: 0;
  }
  &.gg-7 {
    grid-gap: 7px;
  }
}